/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import Slider from 'react-slick';
// @mui
import { Card, Link } from '@mui/material';
// components
import NextLink from 'next/link';
import Image from '../../components/Image';
import { CarouselDots } from '../../components/carousel';
// ----------------------------------------------------------------------

export default function BannerSlider({ sliders }) {
  const settings = {
    speed: 1000,
    dots: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...CarouselDots({ position: 'absolute', right: 24, bottom: 24 }),
  };
  return (
    <Card>
      <Slider {...settings}>
        {sliders?.map((item) => (
          <CarouselItem key={item.id} item={item} />
        ))}
      </Slider>
    </Card>
  );
}

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string,
  }),
};

function CarouselItem({ item }) {
  const { featureImage, targetLink, title } = item;

  return (
    <NextLink href={targetLink} passHref>
      <Link href=''>
        <Image
          alt={title}
          src={featureImage}
          ratio={"16/8"}
        />
      </Link>
    </NextLink>
  );
};
